import { apisStore } from "context/GlobalStates";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { ProgressSpinner } from "primereact/progressspinner";
import { useCallback, useEffect, useRef, useState } from "react";
import { DataApi, DataPoint } from "serviceapi/dataPusherApi";
import { DataType } from "../DataWriter/DataWriter";
import InputsOutputsHistory from "./InputsOutputsHistory";

interface LatestInputsOutputsProps {
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  selectedPipelineId: string;
  dataType: DataType;
}

const LatestInputsOutputs = (props: LatestInputsOutputsProps) => {
  const [data, setData] = useState<{ [key: string]: DataPoint }>();
  const [dataHistory, setDataHistory] = useState<DataPoint[]>();
  const [isDataHistoryDialogVisible, setIsDataHistoryDialogVisible] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const msgs = useRef<Messages>(null);

  const apiInputs = apisStore.getState()?.dataPusher?.dataApi as DataApi;

  const fetchDataHistory = useCallback(
    async (inputName: string) => {
      try {
        setIsLoading(true);
        const response =
          props.dataType === DataType.OUTPUT
            ? await apiInputs.getOutputHistory(
                inputName,
                props.selectedPipelineId
              )
            : await apiInputs.getInputHistory(
                inputName,
                props.selectedPipelineId
              );
        const fetchedDataHistory = response.data;
        setDataHistory(fetchedDataHistory);
        setIsDataHistoryDialogVisible(true);
      } catch (error) {
        console.error("Error fetching data history:", error);
        msgs.current?.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to fetch data history. Please try again later.",
          closable: true,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [apiInputs, props.selectedPipelineId]
  );

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response =
        props.dataType === DataType.OUTPUT
          ? await apiInputs.getLatestOutputs(props.selectedPipelineId)
          : await apiInputs.getLatestInputs(props.selectedPipelineId);

      const fetchedData = response.data;
      setData(fetchedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      msgs.current?.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to fetch data. Please try again later.",
        closable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [apiInputs, props.selectedPipelineId]);

  useEffect(() => {
    if (props.isVisible) {
      fetchData();
    }
  }, [props.isVisible, fetchData]);

  const tableData = data
    ? Object.keys(data).map((key) => ({
        name: key,
        value: data[key].v,
      }))
    : [];

  const renderSelectInputButton = (rowData: any) => {
    return (
      <div className={"button-container"}>
        <Button
          icon={"pi pi-history"}
          className={"button p-button-info"}
          onClick={() => fetchDataHistory(rowData.name)}
        />
      </div>
    );
  };

  return (
    <Dialog
      header={`Latest ${
        props.dataType === DataType.INPUT ? "inputs" : "outputs"
      }`}
      visible={props.isVisible}
      style={{
        width: "30%",
        height: "46%",
        position: "absolute",
        bottom: props.dataType === DataType.INPUT ? undefined : "0px",
        top: props.dataType === DataType.OUTPUT ? undefined : "78px",
        right: "0px",
      }}
      modal={false}
      resizable
      onHide={() => props.setIsVisible(false)}
    >
      <Messages ref={msgs} />
      {isLoading ? (
        <div className="spinner-container">
          <ProgressSpinner />
        </div>
      ) : (
        <>
          <DataTable
            value={tableData}
            size={"small"}
            editMode={"cell"}
            rows={5}
            paginator
          >
            <Column field="name" header="Name" style={{ width: "75%" }} />
            <Column field="value" header="Value" style={{ width: "15%" }} />
            <Column
              body={renderSelectInputButton}
              style={{ width: "10%", textAlign: "center" }}
            />
          </DataTable>
          {dataHistory && isDataHistoryDialogVisible && (
            <InputsOutputsHistory
              dataType={props.dataType}
              dataHistory={dataHistory}
              isDataHistoryDialogVisible={isDataHistoryDialogVisible}
              setIsDataHistoryDialogVisible={setIsDataHistoryDialogVisible}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default LatestInputsOutputs;
