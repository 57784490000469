import React, { useState, useEffect } from "react";
import { Tooltip } from "react-tooltip";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import "./AddPipeInputOrOutput.scss";
import InputOrOutputCreator from "./InputOrOutputCreator";
import { SchemaType } from "serviceapi/dataPusherApi";
import Apis from "serviceapi/ApiImports";

type AddPipeInputOrOutputProps = {
  type: SchemaType;
  addNewOutput?(conf: any): void;
  addNewInput?(conf: any): void;
  allInputsOrOutputs?: Array<any>;
};

const AddPipeInputOrOutput = ({
  type,
  addNewOutput,
  addNewInput,
  allInputsOrOutputs = [],
}: AddPipeInputOrOutputProps) => {
  const inputsorOutputsIds = Array.isArray(allInputsOrOutputs)
    ? allInputsOrOutputs.map((e) => e.id)
    : [];

  const [dialog, setDialog] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(
    type === SchemaType.Input
  );

  useEffect(() => {
    const checkVersion = async () => {
      if (type === SchemaType.Input && allInputsOrOutputs.length > 0) {
        try {
          const version = await Apis("datapusher").versionApi.versionGet();
          if (!version) {
            setIsDisabled(true);
          } else {
            setIsDisabled(false);
          }
        } catch (error) {
          console.error("Failed to fetch version:", error);
          setIsDisabled(true);
        }
      }
    };

    checkVersion();
  }, [type]);

  return (
    <>
      <Tooltip
        id={type === SchemaType.Input ? "add-new-input" : "add-new-output"}
      />
      <Button
        className="add-pipeline-button p-button-success"
        icon="pi pi-plus"
        onClick={() => setDialog(true)}
        data-tooltip-content={
          type === SchemaType.Input ? "Add new input" : "Add new output"
        }
        data-tooltip-id={
          type === SchemaType.Input ? "add-new-input" : "add-new-output"
        }
        disabled={isDisabled}
      />
      {dialog && (
        <Dialog
          onHide={() => setDialog(false)}
          visible={dialog}
          header={`Add new pipeline ${
            type === SchemaType.Input ? "input" : "output"
          }`}
        >
          <InputOrOutputCreator
            type={type}
            addNewConfig={(e) => {
              if (type === SchemaType.Input && addNewInput) {
                addNewInput(e);
              }
              if (type === SchemaType.Output && addNewOutput) {
                addNewOutput(e);
              }
              setDialog(false);
            }}
            existingIds={inputsorOutputsIds}
          />
        </Dialog>
      )}
    </>
  );
};

export default AddPipeInputOrOutput;
