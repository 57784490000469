import React, { useEffect, useState } from "react";
import EditorReadonly from "../../general/EditorReadonly";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Utils from "../../../util/Utils";
import { Button } from "primereact/button";
import { ConfirmPopup, confirmPopup } from "primereact/confirmpopup";
import NotificationPopUp from "../../../util/NotificationPopUp";
import { apisType } from "../../../serviceapi/ApiImports";
import { apisStore } from "../../../context/GlobalStates";
import "./PipelineHistory.scss";

type WriterHistoryProps = {
  pipelineId?: string;
};

enum TabType {
  GENERAL = "General",
  INPUT = "Input",
  OUTPUT = "Output",
  STRING = "String",
}

const PipelineHistory = ({ pipelineId }: WriterHistoryProps) => {
  const [pipelineHistories, setPipelineHistories] = useState<Array<any>>([]);
  const [selectedPipeline, setSelectedPipeline] = useState<any>(undefined);
  const [pipelineText, setPipelineText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<TabType>(TabType.GENERAL);

  const apis: apisType = apisStore.getState().dataPusher;

  useEffect(() => {
    if (pipelineId) {
      updateWriters();
    }
  }, [pipelineId]);

  const updateWriters = () => {
    if (!pipelineId) {
      return;
    }
    apis.writerBackupApi
      ?.getBackups([pipelineId])
      .then((res: any) => {
        setPipelineHistories(res.data);
        setLoading(false);
      })
      .catch((err) => NotificationPopUp.show(err.message, "error"));
  };

  const handlePipleineSelect = (selectedPipeline: any) => {
    setSelectedPipeline(selectedPipeline);
    if (selectedPipeline) {
      apis.writerBackupApi
        ?.getConfigurationBackup(selectedPipeline.id)
        .then((res: any) => {
          setPipelineText(JSON.stringify(res.data, null, 4));
        })
        .catch((err) => NotificationPopUp.show(err.message, "error"));
    }
  };
  const timestampToDate = (rowData: any) =>
    Utils.timestampToReadableDate(rowData.timestamp);

  const restore = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to restore pipeline with this backup?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        apis.writerBackupApi
          ?.restoreBackup(pipelineId!, id)
          .then(() => {
            NotificationPopUp.show(
              "Pipeline was restored successfully",
              "success"
            );
          })
          .catch((err) => NotificationPopUp.show(err.message, "error"));
      },
    });
  };

  const remove = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    id: number
  ) => {
    confirmPopup({
      target: event.currentTarget,
      message: "Are you sure you want to remove pipeline back up?",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        apis.writerBackupApi
          ?.deleteBackup([id])
          .then(() => {
            NotificationPopUp.show(
              "Pipeline backup removed successfully",
              "success"
            );
            updateWriters();
          })
          .catch((err) => console.log(err));
      },
    });
  };

  const getTabContent = () => {
    try {
      const parsedText = JSON.parse(JSON.parse(pipelineText));

      if (activeTab === TabType.INPUT) {
        if (
          Array.isArray(parsedText.inputConfig) &&
          parsedText.inputConfig.length > 0
        ) {
          return JSON.stringify(parsedText.inputConfig[0], null, 4);
        }
        return "Input configuration is empty or undefined.";
      } else if (activeTab === TabType.OUTPUT) {
        if (
          Array.isArray(parsedText.outputConfig) &&
          parsedText.outputConfig.length > 0
        ) {
          return JSON.stringify(parsedText.outputConfig[0], null, 4);
        }
        return "Output configuration is empty or undefined.";
      } else if (activeTab === TabType.STRING) {
        return pipelineText;
      }

      return JSON.stringify(parsedText, null, 4);
    } catch {
      if (pipelineText === "") {
        return "Please select a pipeline to view the configuration.";
      }
    }
  };

  const functionality = (rowData: any) => (
    <div className={"button-container"}>
      <Button
        icon={"pi pi-history"}
        className={"button p-button-info"}
        onClick={(event) => restore(event, rowData.id)}
        tooltip={"Restore writer config"}
      />
      <Button
        icon={"pi pi-trash"}
        className={"button p-button-danger"}
        onClick={(event) => remove(event, rowData.id)}
        tooltip={"Remove writer config backup"}
      />
    </div>
  );

  return (
    <div className={"writer-history-full-window"}>
      <div className={"left-side"}>
        <DataTable
          value={pipelineHistories}
          style={{ maxHeight: "50vw" }}
          sortField={"timestamp"}
          sortOrder={-1}
          selectionMode={"single"}
          selection={selectedPipeline}
          onSelectionChange={(e) => handlePipleineSelect(e.value)}
          loading={loading}
        >
          <Column field="timestamp" header={"Date"} body={timestampToDate} />
          <Column className={"column"} body={functionality} />
        </DataTable>
      </div>
      <div className={"right-side"}>
        <div className={"button-container-scheme-picker"}>
          {[TabType.GENERAL, TabType.INPUT, TabType.OUTPUT, TabType.STRING].map(
            (tab) => (
              <div
                key={tab}
                className={`tab-view-button ${
                  activeTab === tab ? "active" : ""
                }`}
                onClick={() => setActiveTab(tab as TabType)}
              >
                {tab}
              </div>
            )
          )}
        </div>
        <EditorReadonly text={getTabContent()} />
      </div>
      <ConfirmPopup />
    </div>
  );
};

export default PipelineHistory;
