import React from 'react';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import UserService, {Permission} from "../service/UserService";

function Forbidden() {
    const footer = (<div className="flex justify-content-center">
        <Button icon="pi pi-power-off"
                label={"Logout"}
                className="p-button-plain p-button-rounded p-button-danger p-button-sm"
                onClick={() => UserService.doLogout()}
        />
    </div>);

    const titleDiv = () => {
        return <div className={"title-and-image"}>
            EnergyAdvice DataHub
            <img alt="logo"
                 src={process.env.PUBLIC_URL + "/Dween.png"}
                 height={"45px"}
                 className="mr-1"/>
        </div>
    }

    return <div className={"choose-window-full-size"}>
        <Card title={titleDiv()} footer={footer}>
            <div>
                <div>You are not allowed to access DataHub. Missing [{Permission.DATAHUB_USE}] permission.</div>
            </div>
        </Card>
    </div>
}

export default Forbidden;