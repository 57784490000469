import React, { useEffect, useState } from "react";
import NotificationPopUp from "util/NotificationPopUp";
import { ManagementApi, SchemaType } from "serviceapi/dataPusherApi";
import { apisStore } from "context/GlobalStates";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

type InputOrOutputCreatorProps = {
  type: SchemaType;
  addNewConfig: (config: any) => void;
  existingIds?: string[];
};

const InputOrOutputCreator: React.FC<InputOrOutputCreatorProps> = ({
  type,
  addNewConfig,
  existingIds = [],
}) => {
  const managementApi: ManagementApi = (apisStore.getState().dataPusher as any)
    .managementApiConst;

  const [schemaId, setSchemaId] = useState<string>("");
  const [selectedSchema, setSelectedSchema] = useState<string | null>(null);
  const [schemas, setSchemas] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchSchemas = async () => {
      try {
        setIsLoading(true);
        const response = await managementApi.getAllSchemas(type);
        const formattedSchemas = Object.keys(response.data).map((key) => ({
          label: key,
          value: key,
        }));
        setSchemas(formattedSchemas);
      } catch (err) {
        console.error(`Error fetching ${type} schemas:`, err);
        NotificationPopUp.show(`Error fetching ${type} schemas`, "error");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSchemas();
  }, [type]);

  const createConfig = async () => {
    if (!selectedSchema) return;

    try {
      const response = await managementApi.createEmptyConfig(
        selectedSchema,
        type
      );

      const newConfig = { ...response.data, id: schemaId };
      addNewConfig(newConfig);

      NotificationPopUp.show(
        `${
          type.charAt(0).toUpperCase() + type.slice(1)
        } [${schemaId}] created successfully`,
        "success"
      );

      setSchemaId("");
      setSelectedSchema(null);
    } catch (err) {
      console.error(`Error creating ${type}:`, err);
      NotificationPopUp.show(`Error creating ${type}`, "error");
    }
  };

  return (
    <div className="flex flex-column gap-2">
      <InputText
        placeholder={`${type.charAt(0).toUpperCase() + type.slice(1)} ID`}
        className="p-inputtext-sm"
        value={schemaId}
        onChange={(event) => setSchemaId(event.target.value)}
        disabled={isLoading}
      />

      <Dropdown
        value={selectedSchema}
        options={schemas}
        onChange={(e) => setSelectedSchema(e.value)}
        filter
        showClear
        filterBy="label"
        id={`${type}-schema-picker`}
        style={{ width: "100%", minWidth: "200px" }}
        disabled={isLoading || schemas.length === 0}
        placeholder={
          isLoading
            ? "Loading schemas..."
            : `Select a ${type.charAt(0).toUpperCase() + type.slice(1)} Schema`
        }
      />

      <div className="flex">
        <Button
          label="Create"
          className="p-button-sm p-button-success"
          onClick={createConfig}
          disabled={
            schemaId.length < 3 ||
            !selectedSchema ||
            existingIds.includes(schemaId)
          }
        />
      </div>
    </div>
  );
};

export default InputOrOutputCreator;
