import React, { useEffect, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";

type VirtualScrollerProps = {
  lines: JSX.Element[];
  count: number;
  linesSize: number[];
  autoScroll: boolean;
};

const VirtualScroller = ({
  lines,
  count,
  linesSize,
  autoScroll,
}: VirtualScrollerProps) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState<boolean>(autoScroll);

  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: (index) => linesSize[index],
    overscan: 5,
  });

  const handleScroll = () => {
    if (parentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = parentRef.current;
      const isAtBottom =
        Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1;

      if (isAtBottom && autoScroll) {
        setIsAutoScrollEnabled(true);
      } else {
        setIsAutoScrollEnabled(false);
      }
    }
  };

  useEffect(() => {
    if (isAutoScrollEnabled) {
      virtualizer.scrollToIndex(count - 1, { align: "end" });
    }
  }, [lines, isAutoScrollEnabled, virtualizer, count]);

  const items = virtualizer.getVirtualItems();

  let [paddingTop, paddingBottom] =
    items.length > 0
      ? [
          Math.max(0, items[0].start),
          Math.max(0, virtualizer.getTotalSize() - items[items.length - 1].end),
        ]
      : [0, 0];

  return (
    <div
      ref={parentRef}
      style={{
        height: "100%",
        width: "100%",
        overflowY: "auto",
        contain: "strict",
      }}
      onScroll={handleScroll}
    >
      <div style={{ height: virtualizer.getTotalSize() }}>
        <div style={{ paddingTop, paddingBottom }}>
          {items.map((item) => (
            <div
              key={item.key}
              data-index={item.index}
              ref={virtualizer.measureElement}
            >
              {lines[item.index]}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VirtualScroller;
