import React, { useState, useCallback, useId } from "react";
import "./JobRunnerToggleButton.scss";
import { ConfirmDialog } from "primereact/confirmdialog";
import NotificationPopUp from "util/NotificationPopUp";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "react-tooltip";

interface JobRunnerProp {
  jobRunner: any;
  onJobRunnerToggled(jobRunnerId?: string): void;
}

const JobRunnerToggleButton: React.FC<JobRunnerProp> = ({
  jobRunner,
  onJobRunnerToggled,
}) => {
  const id = useId();
  const [visible, setVisible] = useState(false);

  const confirmationHandler = () => {
    setVisible(true);
  };

  const handleChange = useCallback(() => {
    jobRunner
      .reloadConfig()
      .then(() => {
        const conf = JSON.parse(jobRunner.config);
        conf.enabled = !conf.enabled;
        jobRunner.config = JSON.stringify(conf, null, 4);

        const notificationMessage = !jobRunner.enabled
          ? "Starting Job Runner"
          : "Stopping Job Runner";
        NotificationPopUp.show(notificationMessage, "success");
      })
      .then(() => jobRunner.saveConfig())
      .then(() => jobRunner.restart())
      .then(() => {
        const conf = JSON.parse(jobRunner.config);
        jobRunner.enabled = conf.enabled;
      })
      .catch((er: Error) => {
        console.error(er);
      })
      .finally(() => {
        onJobRunnerToggled(jobRunner.id);
      });
  }, [jobRunner, onJobRunnerToggled]);

  const getTooltipMessage = jobRunner.enabled
    ? "Turn Job Runner off"
    : "Turn Job Runner on";

  const getConfirmationMessage = useCallback(() => {
    return jobRunner.enabled
      ? "Do you want to turn off the job runner?"
      : "Do you want to turn on the job runner?";
  }, [jobRunner.enabled]);

  return (
    <div className="toggle-button-custom">
      <Tooltip id={id} place="left" style={{ fontSize: 24 }} />
      <ConfirmDialog
        visible={visible}
        header="Confirmation"
        onHide={() => setVisible(false)}
        message={getConfirmationMessage()}
        icon="pi pi-exclamation-triangle"
        accept={() => {
          handleChange();
        }}
      />
      <InputSwitch
        checked={jobRunner.enabled}
        onChange={confirmationHandler}
        data-tooltip-id={id}
        data-tooltip-content={getTooltipMessage}
        className="jobrunner-switch"
      />
    </div>
  );
};

export default React.memo(JobRunnerToggleButton);
