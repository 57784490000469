/* tslint:disable */
/* eslint-disable */
/**
 * job-runner-app API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';
import { DatasetType } from './dataCollectorApi';

/**
 * 
 * @export
 * @interface AirflowTaskInfoDto
 */
export interface AirflowTaskInfoDto {
    /**
     * 
     * @type {string}
     * @memberof AirflowTaskInfoDto
     */
    'dagTitle': string;
    /**
     * 
     * @type {string}
     * @memberof AirflowTaskInfoDto
     */
    'dagId': string;
    /**
     * 
     * @type {string}
     * @memberof AirflowTaskInfoDto
     */
    'taskTitle': string;
    /**
     * 
     * @type {string}
     * @memberof AirflowTaskInfoDto
     */
    'taskId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AirflowWorkerQueue = {
    Default: 'DEFAULT',
    FastCompute: 'FAST_COMPUTE'
} as const;

export type AirflowWorkerQueue = typeof AirflowWorkerQueue[keyof typeof AirflowWorkerQueue];


/**
 * 
 * @export
 * @enum {string}
 */

export const AttributeType = {
    None: 'NONE',
    Long: 'LONG',
    Double: 'DOUBLE',
    String: 'STRING',
    Datetime: 'DATETIME',
    Instant: 'INSTANT',
    Time: 'TIME'
} as const;

export type AttributeType = typeof AttributeType[keyof typeof AttributeType];


/**
 * 
 * @export
 * @interface CalculationTaskTemplateOptionsDefaults
 */
export interface CalculationTaskTemplateOptionsDefaults {
    /**
     * 
     * @type {number}
     * @memberof CalculationTaskTemplateOptionsDefaults
     */
    'calculationTimeOffset'?: number | null;
    /**
     * 
     * @type {ChronoUnit}
     * @memberof CalculationTaskTemplateOptionsDefaults
     */
    'calculationTimeOffsetUnit': ChronoUnit;
    /**
     * 
     * @type {number}
     * @memberof CalculationTaskTemplateOptionsDefaults
     */
    'calculationPeriod'?: number | null;
    /**
     * 
     * @type {ChronoUnit}
     * @memberof CalculationTaskTemplateOptionsDefaults
     */
    'dataLeadUnit': ChronoUnit;
    /**
     * 
     * @type {number}
     * @memberof CalculationTaskTemplateOptionsDefaults
     */
    'calculationSteps'?: number | null;
    /**
     * 
     * @type {ChronoUnit}
     * @memberof CalculationTaskTemplateOptionsDefaults
     */
    'calculationPeriodUnit': ChronoUnit;
    /**
     * 
     * @type {number}
     * @memberof CalculationTaskTemplateOptionsDefaults
     */
    'dataLead'?: number | null;
    /**
     * 
     * @type {ChronoUnit}
     * @memberof CalculationTaskTemplateOptionsDefaults
     */
    'dataLagUnit': ChronoUnit;
    /**
     * 
     * @type {number}
     * @memberof CalculationTaskTemplateOptionsDefaults
     */
    'dataLag'?: number | null;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ChronoUnit = {
    Nanos: 'NANOS',
    Micros: 'MICROS',
    Millis: 'MILLIS',
    Seconds: 'SECONDS',
    Minutes: 'MINUTES',
    Hours: 'HOURS',
    HalfDays: 'HALF_DAYS',
    Days: 'DAYS',
    Weeks: 'WEEKS',
    Months: 'MONTHS',
    Years: 'YEARS',
    Decades: 'DECADES',
    Centuries: 'CENTURIES',
    Millennia: 'MILLENNIA',
    Eras: 'ERAS',
    Forever: 'FOREVER'
} as const;

export type ChronoUnit = typeof ChronoUnit[keyof typeof ChronoUnit];


/**
 * 
 * @export
 * @interface CodeFileInfo
 */
export interface CodeFileInfo {
    /**
     * 
     * @type {string}
     * @memberof CodeFileInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CodeFileInfo
     */
    'mimeType': string;
    /**
     * 
     * @type {string}
     * @memberof CodeFileInfo
     */
    'uniqueId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DataAccessType = {
    Normal: 'NORMAL',
    Continuous: 'CONTINUOUS',
    DataModelDay: 'DATA_MODEL_DAY'
} as const;

export type DataAccessType = typeof DataAccessType[keyof typeof DataAccessType];


/**
 * 
 * @export
 * @enum {string}
 */

export const DatasetBindingType = {
    MdmDeviceIdentifier: 'MDM_DEVICE_IDENTIFIER',
    MdmObjectIdentifier: 'MDM_OBJECT_IDENTIFIER',
    MdmDeviceId: 'MDM_DEVICE_ID',
    MdmObjectId: 'MDM_OBJECT_ID',
    None: 'NONE'
} as const;

export type DatasetBindingType = typeof DatasetBindingType[keyof typeof DatasetBindingType];


/**
 * 
 * @export
 * @interface DatasetCategoryPythonDTO
 */
export interface DatasetCategoryPythonDTO {
    /**
     * 
     * @type {Array<DatasetPythonDTO>}
     * @memberof DatasetCategoryPythonDTO
     */
    'data': Array<DatasetPythonDTO>;
    /**
     * 
     * @type {number}
     * @memberof DatasetCategoryPythonDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetCategoryPythonDTO
     */
    'title': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DatasetClass = {
    SimpleDataset: 'SIMPLE_DATASET',
    AdvancedDataset: 'ADVANCED_DATASET',
    CategorizedDataset: 'CATEGORIZED_DATASET',
    ElectricityDataset: 'ELECTRICITY_DATASET',
    ProcessedDataset: 'PROCESSED_DATASET',
    ProcessedIntegralDataset: 'PROCESSED_INTEGRAL_DATASET',
    ProcessedPriorityFirstDataset: 'PROCESSED_PRIORITY_FIRST_DATASET',
    ProcessedPythonDataset: 'PROCESSED_PYTHON_DATASET',
    CategorizedStringDataset: 'CATEGORIZED_STRING_DATASET',
    RemoteDataset: 'REMOTE_DATASET',
    RemoteStringDataset: 'REMOTE_STRING_DATASET',
    RemoteCategorizedDataset: 'REMOTE_CATEGORIZED_DATASET'
} as const;

export type DatasetClass = typeof DatasetClass[keyof typeof DatasetClass];


/**
 * 
 * @export
 * @interface DatasetDataInfoResponse
 */
export interface DatasetDataInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof DatasetDataInfoResponse
     */
    'identifier': string;
    /**
     * 
     * @type {number}
     * @memberof DatasetDataInfoResponse
     */
    'stringValueMapper'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DatasetDataInfoResponse
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DatasetDataInfoResponse
     */
    'decimalPlaces'?: number | null;
    /**
     * 
     * @type {Array<InstantDoubleValue>}
     * @memberof DatasetDataInfoResponse
     */
    'data': Array<InstantDoubleValue>;
    /**
     * 
     * @type {string}
     * @memberof DatasetDataInfoResponse
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface DatasetExtendedIdentifier
 */
export interface DatasetExtendedIdentifier {
    /**
     * 
     * @type {DatasetClass}
     * @memberof DatasetExtendedIdentifier
     */
    'datasetClass': DatasetClass;
    /**
     * 
     * @type {string}
     * @memberof DatasetExtendedIdentifier
     */
    'displayString': string;
    /**
     * 
     * @type {number}
     * @memberof DatasetExtendedIdentifier
     */
    'datasetDBId'?: number;
    /**
     * 
     * @type {number}
     * @memberof DatasetExtendedIdentifier
     */
    'groupId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DatasetExtendedIdentifier
     */
    'units': string;
}


/**
 * 
 * @export
 * @interface DatasetIODataPair
 */
export interface DatasetIODataPair {
    /**
     * 
     * @type {DatasetPythonDTO}
     * @memberof DatasetIODataPair
     */
    'output'?: DatasetPythonDTO | null;
    /**
     * 
     * @type {DatasetPythonDTO}
     * @memberof DatasetIODataPair
     */
    'input'?: DatasetPythonDTO | null;
}
/**
 * 
 * @export
 * @interface DatasetIdentifier
 */
export interface DatasetIdentifier {
    /**
     * 
     * @type {DatasetClass}
     * @memberof DatasetIdentifier
     */
    'datasetClass': DatasetClass;
    /**
     * 
     * @type {string}
     * @memberof DatasetIdentifier
     */
    'displayString': string;
    /**
     * 
     * @type {number}
     * @memberof DatasetIdentifier
     */
    'datasetDBId'?: number;
}


/**
 * 
 * @export
 * @interface DatasetInfo
 */
export interface DatasetInfo {
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo
     */
    'identifier'?: string | null;
    /**
     * 
     * @type {DatasetBindingType}
     * @memberof DatasetInfo
     */
    'boundToType'?: DatasetBindingType | null;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetInfo
     */
    'isArchived'?: boolean | null;
    /**
     * 
     * @type {DatasetType}
     * @memberof DatasetInfo
     */
    'dataType'?: DatasetType | null;
    /**
     * 
     * @type {number}
     * @memberof DatasetInfo
     */
    'groupId'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo
     */
    'groupTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo
     */
    'categoryTitle'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetInfo
     */
    'proxied'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DatasetInfo
     */
    'deviceId'?: number | null;
    /**
     * 
     * @type {Set<string>}
     * @memberof DatasetInfo
     */
    'tags'?: Set<string> | null;
    /**
     * 
     * @type {DataAccessType}
     * @memberof DatasetInfo
     */
    'accessType'?: DataAccessType | null;
    /**
     * 
     * @type {number}
     * @memberof DatasetInfo
     */
    'stringValueMapper'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetInfo
     */
    'archived'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo
     */
    'unit'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DatasetInfo
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {DatasetClass}
     * @memberof DatasetInfo
     */
    'datasetClass'?: DatasetClass | null;
    /**
     * 
     * @type {number}
     * @memberof DatasetInfo
     */
    'decimalPlaces'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetInfo
     */
    'deleted'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof DatasetInfo
     */
    'isProxied'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof DatasetInfo
     */
    'boundToId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DatasetInfo
     */
    'categoryId'?: number | null;
}


/**
 * 
 * @export
 * @interface DatasetPythonDTO
 */
export interface DatasetPythonDTO {
    /**
     * 
     * @type {DatasetIdentifier}
     * @memberof DatasetPythonDTO
     */
    'identifier': DatasetIdentifier;
    /**
     * 
     * @type {string}
     * @memberof DatasetPythonDTO
     */
    'unit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DatasetPythonDTO
     */
    'clientId'?: string | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof DatasetPythonDTO
     */
    'data'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {string}
     * @memberof DatasetPythonDTO
     */
    'groupTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DatasetPythonDTO
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

// export const DatasetType = {
//     Hourly: 'HOURLY',
//     Daily: 'DAILY',
//     Arbitrary: 'ARBITRARY',
//     Monthly: 'MONTHLY',
//     Weekly: 'WEEKLY',
//     Seconds: 'SECONDS',
//     Minutes: 'MINUTES',
//     ArbitraryMinutes: 'ARBITRARY_MINUTES',
//     ArbitrarySeconds: 'ARBITRARY_SECONDS'
// } as const;

// export type DatasetType = typeof DatasetType[keyof typeof DatasetType];


/**
 * 
 * @export
 * @interface InstantDoubleValue
 */
export interface InstantDoubleValue {
    /**
     * 
     * @type {number}
     * @memberof InstantDoubleValue
     */
    'value'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InstantDoubleValue
     */
    'key': string;
}
/**
 * 
 * @export
 * @interface InstantJobConfigCodeFile
 */
export interface InstantJobConfigCodeFile {
    /**
     * 
     * @type {string}
     * @memberof InstantJobConfigCodeFile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof InstantJobConfigCodeFile
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface JobInfoDTO
 */
export interface JobInfoDTO {
    /**
     * 
     * @type {Schedule}
     * @memberof JobInfoDTO
     */
    'schedule': Schedule;
    /**
     * 
     * @type {string}
     * @memberof JobInfoDTO
     */
    'taskTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof JobInfoDTO
     */
    'taskId': string;
    /**
     * 
     * @type {boolean}
     * @memberof JobInfoDTO
     */
    'enabled'?: boolean;
}
/**
 * 
 * @export
 * @interface ObjectId
 */
export interface ObjectId {
    /**
     * 
     * @type {string}
     * @memberof ObjectId
     */
    'id': string;
}
/**
 * 
 * @export
 * @interface ProductEntryData
 */
export interface ProductEntryData {
    /**
     * 
     * @type {boolean}
     * @memberof ProductEntryData
     */
    'null'?: boolean;
    /**
     * 
     * @type {AttributeType}
     * @memberof ProductEntryData
     */
    'type': AttributeType;
    /**
     * 
     * @type {any}
     * @memberof ProductEntryData
     */
    'value'?: any;
    /**
     * 
     * @type {string}
     * @memberof ProductEntryData
     */
    'referenceName': string;
}


/**
 * 
 * @export
 * @interface ProductTemplateTaskSelectionOptions
 */
export interface ProductTemplateTaskSelectionOptions {
    /**
     * 
     * @type {string}
     * @memberof ProductTemplateTaskSelectionOptions
     */
    'productTemplateRef'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProductTemplateTaskSelectionOptions
     */
    'useDataset'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof ProductTemplateTaskSelectionOptions
     */
    'latestEntryCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductTemplateTaskSelectionOptions
     */
    'id'?: number | null;
    /**
     * 
     * @type {DatasetIdentifier}
     * @memberof ProductTemplateTaskSelectionOptions
     */
    'entryDataset'?: DatasetIdentifier | null;
    /**
     * 
     * @type {string}
     * @memberof ProductTemplateTaskSelectionOptions
     */
    'filterQuery'?: string | null;
}
/**
 * 
 * @export
 * @interface PythonClientDataset
 */
export interface PythonClientDataset {
    /**
     * 
     * @type {string}
     * @memberof PythonClientDataset
     */
    'datasetTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PythonClientDataset
     */
    'clientId'?: string | null;
    /**
     * 
     * @type {Array<TimeMomentValue>}
     * @memberof PythonClientDataset
     */
    'data': Array<TimeMomentValue>;
    /**
     * 
     * @type {string}
     * @memberof PythonClientDataset
     */
    'categoryTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PythonClientDataset
     */
    'categoryId'?: number | null;
}
/**
 * 
 * @export
 * @interface PythonCodeFileDTO
 */
export interface PythonCodeFileDTO {
    /**
     * 
     * @type {string}
     * @memberof PythonCodeFileDTO
     */
    'codeB64'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PythonCodeFileDTO
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PythonCodeFileDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PythonCodeFileDTO
     */
    'id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PythonCodeFileDTO
     */
    'uniqueId'?: string | null;
}
/**
 * 
 * @export
 * @interface PythonNamedDatasetWrapper
 */
export interface PythonNamedDatasetWrapper {
    /**
     * 
     * @type {DatasetExtendedIdentifier}
     * @memberof PythonNamedDatasetWrapper
     */
    'datasetIdentifier': DatasetExtendedIdentifier;
    /**
     * 
     * @type {Array<TimeMomentValue>}
     * @memberof PythonNamedDatasetWrapper
     */
    'data': Array<TimeMomentValue>;
    /**
     * 
     * @type {Set<string>}
     * @memberof PythonNamedDatasetWrapper
     */
    'tags': Set<string>;
}
/**
 * 
 * @export
 * @interface PythonProductEntry
 */
export interface PythonProductEntry {
    /**
     * 
     * @type {ObjectId}
     * @memberof PythonProductEntry
     */
    'dbId'?: ObjectId | null;
    /**
     * 
     * @type {Array<ProductEntryData>}
     * @memberof PythonProductEntry
     */
    'entryData': Array<ProductEntryData>;
}
/**
 * 
 * @export
 * @interface PythonProductTemplateData
 */
export interface PythonProductTemplateData {
    /**
     * 
     * @type {string}
     * @memberof PythonProductTemplateData
     */
    'keyReference': string;
    /**
     * 
     * @type {Array<PythonProductEntry>}
     * @memberof PythonProductTemplateData
     */
    'entries': Array<PythonProductEntry>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PythonProductTemplateData
     */
    'deletedEntriesIds': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PythonProductTemplateData
     */
    'metaKeyReference': string;
    /**
     * 
     * @type {string}
     * @memberof PythonProductTemplateData
     */
    'safeName': string;
    /**
     * 
     * @type {number}
     * @memberof PythonProductTemplateData
     */
    'templateDbId'?: number;
}
/**
 * 
 * @export
 * @interface PythonTaskData
 */
export interface PythonTaskData {
    /**
     * 
     * @type {PythonTaskMetaData}
     * @memberof PythonTaskData
     */
    'metaData'?: PythonTaskMetaData | null;
    /**
     * 
     * @type {{ [key: string]: PythonProductTemplateData; }}
     * @memberof PythonTaskData
     */
    'templates': { [key: string]: PythonProductTemplateData; };
    /**
     * 
     * @type {{ [key: string]: PythonNamedDatasetWrapper; }}
     * @memberof PythonTaskData
     */
    'outputNamedDatasets': { [key: string]: PythonNamedDatasetWrapper; };
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PythonTaskData
     */
    'constants': { [key: string]: any; };
    /**
     * 
     * @type {Array<Array<TimeMomentValue>>}
     * @memberof PythonTaskData
     */
    'datasets': Array<Array<TimeMomentValue>>;
    /**
     * 
     * @type {{ [key: string]: Array<PythonClientDataset>; }}
     * @memberof PythonTaskData
     */
    'categories': { [key: string]: Array<PythonClientDataset>; };
    /**
     * 
     * @type {{ [key: string]: Array<PythonTaskGroupedData>; }}
     * @memberof PythonTaskData
     */
    'dataGroups': { [key: string]: Array<PythonTaskGroupedData>; };
    /**
     * 
     * @type {{ [key: string]: PythonNamedDatasetWrapper; }}
     * @memberof PythonTaskData
     */
    'inputNamedDatasets': { [key: string]: PythonNamedDatasetWrapper; };
}
/**
 * 
 * @export
 * @interface PythonTaskGroupedData
 */
export interface PythonTaskGroupedData {
    /**
     * 
     * @type {{ [key: string]: PythonProductTemplateData; }}
     * @memberof PythonTaskGroupedData
     */
    'productTemplates'?: { [key: string]: PythonProductTemplateData; } | null;
    /**
     * 
     * @type {{ [key: string]: Array<DatasetIODataPair>; }}
     * @memberof PythonTaskGroupedData
     */
    'datasetInputOutputPairs'?: { [key: string]: Array<DatasetIODataPair>; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PythonTaskGroupedData
     */
    'freestyleJson'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: Array<DatasetPythonDTO>; }}
     * @memberof PythonTaskGroupedData
     */
    'inputIndexedDatasets'?: { [key: string]: Array<DatasetPythonDTO>; } | null;
    /**
     * 
     * @type {{ [key: string]: Array<DatasetPythonDTO>; }}
     * @memberof PythonTaskGroupedData
     */
    'outputIndexedDatasets'?: { [key: string]: Array<DatasetPythonDTO>; } | null;
    /**
     * 
     * @type {{ [key: string]: DatasetPythonDTO; }}
     * @memberof PythonTaskGroupedData
     */
    'outputNamedDatasets'?: { [key: string]: DatasetPythonDTO; } | null;
    /**
     * 
     * @type {{ [key: string]: DatasetCategoryPythonDTO; }}
     * @memberof PythonTaskGroupedData
     */
    'outputNamedCategories'?: { [key: string]: DatasetCategoryPythonDTO; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PythonTaskGroupedData
     */
    'constants'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: DatasetCategoryPythonDTO; }}
     * @memberof PythonTaskGroupedData
     */
    'inputNamedCategories'?: { [key: string]: DatasetCategoryPythonDTO; } | null;
    /**
     * 
     * @type {{ [key: string]: DatasetPythonDTO; }}
     * @memberof PythonTaskGroupedData
     */
    'inputNamedDatasets'?: { [key: string]: DatasetPythonDTO; } | null;
}
/**
 * 
 * @export
 * @interface PythonTaskInputGroup
 */
export interface PythonTaskInputGroup {
    /**
     * 
     * @type {boolean}
     * @memberof PythonTaskInputGroup
     */
    'grouped'?: boolean;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PythonTaskInputGroup
     */
    'outputIndexedDatasets'?: { [key: string]: object; } | null;
    /**
     * 
     * @type {{ [key: string]: DatasetIdentifier; }}
     * @memberof PythonTaskInputGroup
     */
    'outputNamedDatasets'?: { [key: string]: DatasetIdentifier; } | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PythonTaskInputGroup
     */
    'outputNamedCategories'?: { [key: string]: number; } | null;
    /**
     * 
     * @type {string}
     * @memberof PythonTaskInputGroup
     */
    'title'?: string | null;
    /**
     * 
     * @type {{ [key: string]: DatasetIdentifier; }}
     * @memberof PythonTaskInputGroup
     */
    'inputNamedDatasets'?: { [key: string]: DatasetIdentifier; } | null;
    /**
     * 
     * @type {{ [key: string]: ProductTemplateTaskSelectionOptions; }}
     * @memberof PythonTaskInputGroup
     */
    'productTemplates'?: { [key: string]: ProductTemplateTaskSelectionOptions; } | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PythonTaskInputGroup
     */
    'datasetInputOutputPairs'?: { [key: string]: object; } | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PythonTaskInputGroup
     */
    'freestyleJson'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PythonTaskInputGroup
     */
    'inputIndexedDatasets'?: { [key: string]: object; } | null;
    /**
     * 
     * @type {boolean}
     * @memberof PythonTaskInputGroup
     */
    'isGrouped'?: boolean;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof PythonTaskInputGroup
     */
    'constants'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof PythonTaskInputGroup
     */
    'inputNamedCategories'?: { [key: string]: number; } | null;
}
/**
 * 
 * @export
 * @interface PythonTaskMetaData
 */
export interface PythonTaskMetaData {
    /**
     * 
     * @type {number}
     * @memberof PythonTaskMetaData
     */
    'categoryLagSeconds'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PythonTaskMetaData
     */
    'calculationTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PythonTaskMetaData
     */
    'lagSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PythonTaskMetaData
     */
    'leadSeconds'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PythonTaskMetaData
     */
    'totalPeriods'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PythonTaskMetaData
     */
    'currentPeriod'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PythonTaskMetaData
     */
    'categoryLeadSeconds'?: number | null;
}
/**
 * 
 * @export
 * @interface Schedule
 */
export interface Schedule {
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'cron': string;
    /**
     * 
     * @type {string}
     * @memberof Schedule
     */
    'timeZone'?: string | null;
}
/**
 * 
 * @export
 * @interface ScheduledTaskDTO
 */
export interface ScheduledTaskDTO {
    /**
     * 
     * @type {ChronoUnit}
     * @memberof ScheduledTaskDTO
     */
    'calculationTimeOffsetUnit': ChronoUnit;
    /**
     * 
     * @type {ChronoUnit}
     * @memberof ScheduledTaskDTO
     */
    'categoryDataLagUnit': ChronoUnit;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskDTO
     */
    'upstreamTasks': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'dataLag'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskDTO
     */
    'extraExecutionRecipients': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'timeout'?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ScheduledTaskDTO
     */
    'inputCategories': { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'codeFileId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskDTO
     */
    'skipped'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTO
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'calculationPeriod'?: number;
    /**
     * 
     * @type {{ [key: string]: ProductTemplateTaskSelectionOptions; }}
     * @memberof ScheduledTaskDTO
     */
    'productTemplates': { [key: string]: ProductTemplateTaskSelectionOptions; };
    /**
     * 
     * @type {{ [key: string]: DatasetIdentifier; }}
     * @memberof ScheduledTaskDTO
     */
    'namedInputDatasets': { [key: string]: DatasetIdentifier; };
    /**
     * 
     * @type {ChronoUnit}
     * @memberof ScheduledTaskDTO
     */
    'dataLeadUnit': ChronoUnit;
    /**
     * 
     * @type {{ [key: string]: Array<PythonTaskInputGroup>; }}
     * @memberof ScheduledTaskDTO
     */
    'inputGroups': { [key: string]: Array<PythonTaskInputGroup>; };
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskDTO
     */
    'isSkipped'?: boolean;
    /**
     * 
     * @type {ChronoUnit}
     * @memberof ScheduledTaskDTO
     */
    'calculationPeriodUnit': ChronoUnit;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTO
     */
    'id': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ScheduledTaskDTO
     */
    'constants': { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskDTO
     */
    'allowEditLocked'?: boolean;
    /**
     * 
     * @type {ChronoUnit}
     * @memberof ScheduledTaskDTO
     */
    'categoryDataLeadUnit': ChronoUnit;
    /**
     * 
     * @type {ChronoUnit}
     * @memberof ScheduledTaskDTO
     */
    'dataLagUnit': ChronoUnit;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'categoryDataLead'?: number;
    /**
     * 
     * @type {{ [key: string]: DatasetIdentifier; }}
     * @memberof ScheduledTaskDTO
     */
    'namedOutputDatasets': { [key: string]: DatasetIdentifier; };
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'calculationSteps'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskDTO
     */
    'overrideRecipients'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTO
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {Array<DatasetIdentifier>}
     * @memberof ScheduledTaskDTO
     */
    'indexedOutputDatasets': Array<DatasetIdentifier>;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'categoryDataLag'?: number;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'calculationTimeOffset'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskDTO
     */
    'fillMissingData'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskDTO
     */
    'downstreamTasks': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskDTO
     */
    'dataLead'?: number;
    /**
     * 
     * @type {Array<DatasetIdentifier>}
     * @memberof ScheduledTaskDTO
     */
    'indexedInputDatasets': Array<DatasetIdentifier>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ScheduledTaskDTO
     */
    'outputCategories': { [key: string]: number; };
}


/**
 * 
 * @export
 * @interface ScheduledTaskDTOTemplateInfo
 */
export interface ScheduledTaskDTOTemplateInfo {
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTOTemplateInfo
     */
    'descriptionMd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTOTemplateInfo
     */
    'templateVersion'?: string | null;
    /**
     * 
     * @type {TaskTemplateInfoDefinition}
     * @memberof ScheduledTaskDTOTemplateInfo
     */
    'definition'?: TaskTemplateInfoDefinition | null;
    /**
     * 
     * @type {TemplatingType}
     * @memberof ScheduledTaskDTOTemplateInfo
     */
    'type': TemplatingType;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskDTOTemplateInfo
     */
    'templateId'?: string | null;
}


/**
 * 
 * @export
 * @interface ScheduledTaskExtendedDTO
 */
export interface ScheduledTaskExtendedDTO {
    /**
     * 
     * @type {any}
     * @memberof ScheduledTaskExtendedDTO
     */
    'calculationTimeOffsetUnit'?: any;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskExtendedDTO
     */
    'useCategories'?: boolean | null;
    /**
     * 
     * @type {any}
     * @memberof ScheduledTaskExtendedDTO
     */
    'categoryDataLagUnit'?: any;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'code'?: string | null;
    /**
     * 
     * @type {ScheduledTaskDTOTemplateInfo}
     * @memberof ScheduledTaskExtendedDTO
     */
    'templateInfo'?: ScheduledTaskDTOTemplateInfo | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskExtendedDTO
     */
    'upstreamTasks': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'title'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskExtendedDTO
     */
    'extraExecutionRecipients': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'dataLag'?: number | null;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ScheduledTaskExtendedDTO
     */
    'inputCategories': { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'timeout'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'codeFileId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskExtendedDTO
     */
    'skipped'?: boolean | null;
    /**
     * 
     * @type {AirflowWorkerQueue}
     * @memberof ScheduledTaskExtendedDTO
     */
    'executorQueue'?: AirflowWorkerQueue | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'codeB64'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'calculationPeriod'?: number | null;
    /**
     * 
     * @type {{ [key: string]: ProductTemplateTaskSelectionOptions; }}
     * @memberof ScheduledTaskExtendedDTO
     */
    'productTemplates': { [key: string]: ProductTemplateTaskSelectionOptions; };
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'environmentId'?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ScheduledTaskExtendedDTO
     */
    'namedInputDatasets': { [key: string]: string; };
    /**
     * 
     * @type {any}
     * @memberof ScheduledTaskExtendedDTO
     */
    'dataLeadUnit'?: any;
    /**
     * 
     * @type {{ [key: string]: Array<PythonTaskInputGroup>; }}
     * @memberof ScheduledTaskExtendedDTO
     */
    'inputGroups': { [key: string]: Array<PythonTaskInputGroup>; };
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskExtendedDTO
     */
    'isSkipped'?: boolean | null;
    /**
     * 
     * @type {any}
     * @memberof ScheduledTaskExtendedDTO
     */
    'calculationPeriodUnit'?: any;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ScheduledTaskExtendedDTO
     */
    'constants': { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'id'?: string | null;
    /**
     * 
     * @type {any}
     * @memberof ScheduledTaskExtendedDTO
     */
    'categoryDataLeadUnit'?: any;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskExtendedDTO
     */
    'allowEditLocked'?: boolean | null;
    /**
     * 
     * @type {any}
     * @memberof ScheduledTaskExtendedDTO
     */
    'dataLagUnit'?: any;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'lastUpdatedBy'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'categoryDataLead'?: number | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ScheduledTaskExtendedDTO
     */
    'namedOutputDatasets': { [key: string]: string; };
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'calculationSteps'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskExtendedDTO
     */
    'overrideRecipients'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskExtendedDTO
     */
    'indexedOutputDatasets': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'parentId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'companyRef'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'categoryDataLag'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'calculationTimeOffset'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'companyId'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskExtendedDTO
     */
    'fillMissingData'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskExtendedDTO
     */
    'downstreamTasks': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskExtendedDTO
     */
    'codeFileName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'dataLead'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ScheduledTaskExtendedDTO
     */
    'queueSlots'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScheduledTaskExtendedDTO
     */
    'indexedInputDatasets': Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof ScheduledTaskExtendedDTO
     */
    'outputCategories': { [key: string]: number; };
}


/**
 * 
 * @export
 * @interface ScheduledTaskHistoryDTO
 */
export interface ScheduledTaskHistoryDTO {
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskHistoryDTO
     */
    'taskDagId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskHistoryDTO
     */
    'changeByCompanyTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskHistoryDTO
     */
    'changeBy'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskHistoryDTO
     */
    'airflow'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskHistoryDTO
     */
    'changeByCompanyRef'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskHistoryDTO
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskHistoryDTO
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskHistoryDTO
     */
    'oldId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskHistoryDTO
     */
    'config'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskHistoryDTO
     */
    'taskId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ScheduledTaskHistoryDTO
     */
    'isAirflow'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ScheduledTaskHistoryDTO
     */
    'changeOn'?: string | null;
}
/**
 * 
 * @export
 * @interface TaskTemplateDefinitionOptions
 */
export interface TaskTemplateDefinitionOptions {
    /**
     * 
     * @type {CalculationTaskTemplateOptionsDefaults}
     * @memberof TaskTemplateDefinitionOptions
     */
    'defaults'?: CalculationTaskTemplateOptionsDefaults | null;
}
/**
 * 
 * @export
 * @interface TaskTemplateInfoDefinition
 */
export interface TaskTemplateInfoDefinition {
    /**
     * 
     * @type {InstantJobConfigCodeFile}
     * @memberof TaskTemplateInfoDefinition
     */
    'codeFile'?: InstantJobConfigCodeFile | null;
    /**
     * 
     * @type {Array<TaskTemplateInputGroupDefinition>}
     * @memberof TaskTemplateInfoDefinition
     */
    'inputGroupDefinitions'?: Array<TaskTemplateInputGroupDefinition> | null;
    /**
     * 
     * @type {TaskTemplateDefinitionOptions}
     * @memberof TaskTemplateInfoDefinition
     */
    'options'?: TaskTemplateDefinitionOptions | null;
}
/**
 * 
 * @export
 * @interface TaskTemplateInputDefinition
 */
export interface TaskTemplateInputDefinition {
    /**
     * 
     * @type {number}
     * @memberof TaskTemplateInputDefinition
     */
    'width'?: number | null;
    /**
     * 
     * @type {TaskTemplateInputDefinitionOptions}
     * @memberof TaskTemplateInputDefinition
     */
    'options'?: TaskTemplateInputDefinitionOptions | null;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateInputDefinition
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateInputDefinition
     */
    'title': string;
    /**
     * 
     * @type {TaskTemplateInputType}
     * @memberof TaskTemplateInputDefinition
     */
    'type': TaskTemplateInputType;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateInputDefinition
     */
    'info'?: string | null;
}


/**
 * 
 * @export
 * @interface TaskTemplateInputDefinitionOptions
 */
export interface TaskTemplateInputDefinitionOptions {
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateInputDefinitionOptions
     */
    'unit'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TaskTemplateInputDefinitionOptions
     */
    'selectionsOptional'?: boolean | null;
    /**
     * 
     * @type {any}
     * @memberof TaskTemplateInputDefinitionOptions
     */
    'customConfig'?: any;
    /**
     * 
     * @type {any}
     * @memberof TaskTemplateInputDefinitionOptions
     */
    'defaultValue'?: any;
    /**
     * 
     * @type {boolean}
     * @memberof TaskTemplateInputDefinitionOptions
     */
    'lockable'?: boolean | null;
    /**
     * 
     * @type {Array<any>}
     * @memberof TaskTemplateInputDefinitionOptions
     */
    'selectOptions'?: Array<any> | null;
    /**
     * 
     * @type {TaskTemplateInputSubType}
     * @memberof TaskTemplateInputDefinitionOptions
     */
    'subType'?: TaskTemplateInputSubType | null;
}


/**
 * 
 * @export
 * @interface TaskTemplateInputFormSubgroup
 */
export interface TaskTemplateInputFormSubgroup {
    /**
     * 
     * @type {Array<TaskTemplateInputDefinition>}
     * @memberof TaskTemplateInputFormSubgroup
     */
    'inputs'?: Array<TaskTemplateInputDefinition> | null;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateInputFormSubgroup
     */
    'header'?: string | null;
}
/**
 * 
 * @export
 * @interface TaskTemplateInputGroupDefinition
 */
export interface TaskTemplateInputGroupDefinition {
    /**
     * 
     * @type {Array<TaskTemplateInputFormSubgroup>}
     * @memberof TaskTemplateInputGroupDefinition
     */
    'subForms'?: Array<TaskTemplateInputFormSubgroup> | null;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateInputGroupDefinition
     */
    'header': string;
    /**
     * 
     * @type {string}
     * @memberof TaskTemplateInputGroupDefinition
     */
    'id': string;
    /**
     * 
     * @type {TaskTemplateInputGroupType}
     * @memberof TaskTemplateInputGroupDefinition
     */
    'type': TaskTemplateInputGroupType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const TaskTemplateInputGroupType = {
    Simple: 'SIMPLE',
    Grouped: 'GROUPED'
} as const;

export type TaskTemplateInputGroupType = typeof TaskTemplateInputGroupType[keyof typeof TaskTemplateInputGroupType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TaskTemplateInputSubType = {
    String: 'STRING',
    Number: 'NUMBER',
    Date: 'DATE',
    Boolean: 'BOOLEAN',
    XyValueMatrix: 'XY_VALUE_MATRIX'
} as const;

export type TaskTemplateInputSubType = typeof TaskTemplateInputSubType[keyof typeof TaskTemplateInputSubType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TaskTemplateInputType = {
    Constant: 'CONSTANT',
    ProductTemplate: 'PRODUCT_TEMPLATE',
    DatasetPairs: 'DATASET_PAIRS',
    InputIndexedDatasets: 'INPUT_INDEXED_DATASETS',
    InputNamedDataset: 'INPUT_NAMED_DATASET',
    InputNamedCategory: 'INPUT_NAMED_CATEGORY',
    OutputIndexedDatasets: 'OUTPUT_INDEXED_DATASETS',
    OutputNamedDataset: 'OUTPUT_NAMED_DATASET',
    OutputNamedCategory: 'OUTPUT_NAMED_CATEGORY',
    FreestyleJson: 'FREESTYLE_JSON',
    Comment: 'COMMENT'
} as const;

export type TaskTemplateInputType = typeof TaskTemplateInputType[keyof typeof TaskTemplateInputType];


/**
 * 
 * @export
 * @enum {string}
 */

export const TemplatingType = {
    None: 'NONE',
    UserCustom: 'USER_CUSTOM',
    Predefined: 'PREDEFINED'
} as const;

export type TemplatingType = typeof TemplatingType[keyof typeof TemplatingType];


/**
 * 
 * @export
 * @interface TimeMomentValue
 */
export interface TimeMomentValue {
    /**
     * 
     * @type {number}
     * @memberof TimeMomentValue
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof TimeMomentValue
     */
    'key': string;
}

/**
 * EasasAirflowApi - axios parameter creator
 * @export
 */
export const EasasAirflowApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Airflow Tasks Info
         * @param {string} dagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirflowTasksInfo: async (dagId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dagId' is not null or undefined
            assertParamExists('getAirflowTasksInfo', 'dagId', dagId)
            const localVarPath = `/easas/airflow/dag/{dagId}/task-infos`
                .replace(`{${"dagId"}}`, encodeURIComponent(String(dagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EasasAirflowApi - functional programming interface
 * @export
 */
export const EasasAirflowApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EasasAirflowApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Airflow Tasks Info
         * @param {string} dagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAirflowTasksInfo(dagId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AirflowTaskInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAirflowTasksInfo(dagId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EasasAirflowApi.getAirflowTasksInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EasasAirflowApi - factory interface
 * @export
 */
export const EasasAirflowApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EasasAirflowApiFp(configuration)
    return {
        /**
         * Get Airflow Tasks Info
         * @param {string} dagId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirflowTasksInfo(dagId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<AirflowTaskInfoDto>> {
            return localVarFp.getAirflowTasksInfo(dagId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EasasAirflowApi - object-oriented interface
 * @export
 * @class EasasAirflowApi
 * @extends {BaseAPI}
 */
export class EasasAirflowApi extends BaseAPI {
    /**
     * Get Airflow Tasks Info
     * @param {string} dagId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EasasAirflowApi
     */
    public getAirflowTasksInfo(dagId: string, options?: RawAxiosRequestConfig) {
        return EasasAirflowApiFp(this.configuration).getAirflowTasksInfo(dagId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EasasDatasetsApi - axios parameter creator
 * @export
 */
export const EasasDatasetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get Data Info Recent
         * @param {Array<DatasetIdentifier>} datasetIdentifier 
         * @param {number} [count] 
         * @param {string} [refTime] 
         * @param {boolean} [strict] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataInfoRecent: async (datasetIdentifier: Array<DatasetIdentifier>, count?: number, refTime?: string, strict?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'datasetIdentifier' is not null or undefined
            assertParamExists('getDataInfoRecent', 'datasetIdentifier', datasetIdentifier)
            const localVarPath = `/easas/datasetData/dataInfoRecent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (refTime !== undefined) {
                for (const [key, value] of Object.entries(refTime)) {
                    localVarQueryParameter[key] = value;
                }
            }

            if (strict !== undefined) {
                localVarQueryParameter['strict'] = strict;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(datasetIdentifier, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query Datasets
         * @param {boolean} [allowEmptyQuery] 
         * @param {string | null} [companyRef] 
         * @param {boolean | null} [deleted] 
         * @param {string | null} [identifiers] 
         * @param {number | null} [limit] 
         * @param {number | null} [page] 
         * @param {string | null} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDatasets: async (allowEmptyQuery?: boolean, companyRef?: string | null, deleted?: boolean | null, identifiers?: string | null, limit?: number | null, page?: number | null, query?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/easas/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (allowEmptyQuery !== undefined) {
                localVarQueryParameter['allowEmptyQuery'] = allowEmptyQuery;
            }

            if (companyRef !== undefined) {
                localVarQueryParameter['companyRef'] = companyRef;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (identifiers !== undefined) {
                localVarQueryParameter['identifiers'] = identifiers;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EasasDatasetsApi - functional programming interface
 * @export
 */
export const EasasDatasetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EasasDatasetsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get Data Info Recent
         * @param {Array<DatasetIdentifier>} datasetIdentifier 
         * @param {number} [count] 
         * @param {string} [refTime] 
         * @param {boolean} [strict] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDataInfoRecent(datasetIdentifier: Array<DatasetIdentifier>, count?: number, refTime?: string, strict?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatasetDataInfoResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDataInfoRecent(datasetIdentifier, count, refTime, strict, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EasasDatasetsApi.getDataInfoRecent']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Query Datasets
         * @param {boolean} [allowEmptyQuery] 
         * @param {string | null} [companyRef] 
         * @param {boolean | null} [deleted] 
         * @param {string | null} [identifiers] 
         * @param {number | null} [limit] 
         * @param {number | null} [page] 
         * @param {string | null} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async queryDatasets(allowEmptyQuery?: boolean, companyRef?: string | null, deleted?: boolean | null, identifiers?: string | null, limit?: number | null, page?: number | null, query?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatasetInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.queryDatasets(allowEmptyQuery, companyRef, deleted, identifiers, limit, page, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EasasDatasetsApi.queryDatasets']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EasasDatasetsApi - factory interface
 * @export
 */
export const EasasDatasetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EasasDatasetsApiFp(configuration)
    return {
        /**
         * Get Data Info Recent
         * @param {Array<DatasetIdentifier>} datasetIdentifier 
         * @param {number} [count] 
         * @param {string} [refTime] 
         * @param {boolean} [strict] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDataInfoRecent(datasetIdentifier: Array<DatasetIdentifier>, count?: number, refTime?: string, strict?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<Array<DatasetDataInfoResponse>> {
            return localVarFp.getDataInfoRecent(datasetIdentifier, count, refTime, strict, options).then((request) => request(axios, basePath));
        },
        /**
         * Query Datasets
         * @param {boolean} [allowEmptyQuery] 
         * @param {string | null} [companyRef] 
         * @param {boolean | null} [deleted] 
         * @param {string | null} [identifiers] 
         * @param {number | null} [limit] 
         * @param {number | null} [page] 
         * @param {string | null} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        queryDatasets(allowEmptyQuery?: boolean, companyRef?: string | null, deleted?: boolean | null, identifiers?: string | null, limit?: number | null, page?: number | null, query?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<Array<DatasetInfo>> {
            return localVarFp.queryDatasets(allowEmptyQuery, companyRef, deleted, identifiers, limit, page, query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EasasDatasetsApi - object-oriented interface
 * @export
 * @class EasasDatasetsApi
 * @extends {BaseAPI}
 */
export class EasasDatasetsApi extends BaseAPI {
    /**
     * Get Data Info Recent
     * @param {Array<DatasetIdentifier>} datasetIdentifier 
     * @param {number} [count] 
     * @param {string} [refTime] 
     * @param {boolean} [strict] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EasasDatasetsApi
     */
    public getDataInfoRecent(datasetIdentifier: Array<DatasetIdentifier>, count?: number, refTime?: string, strict?: boolean, options?: RawAxiosRequestConfig) {
        return EasasDatasetsApiFp(this.configuration).getDataInfoRecent(datasetIdentifier, count, refTime, strict, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query Datasets
     * @param {boolean} [allowEmptyQuery] 
     * @param {string | null} [companyRef] 
     * @param {boolean | null} [deleted] 
     * @param {string | null} [identifiers] 
     * @param {number | null} [limit] 
     * @param {number | null} [page] 
     * @param {string | null} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EasasDatasetsApi
     */
    public queryDatasets(allowEmptyQuery?: boolean, companyRef?: string | null, deleted?: boolean | null, identifiers?: string | null, limit?: number | null, page?: number | null, query?: string | null, options?: RawAxiosRequestConfig) {
        return EasasDatasetsApiFp(this.configuration).queryDatasets(allowEmptyQuery, companyRef, deleted, identifiers, limit, page, query, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EasasScheduledTasksApi - axios parameter creator
 * @export
 */
export const EasasScheduledTasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Download Task Code
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTaskCode: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadTaskCode', 'id', id)
            const localVarPath = `/easas/scheduled-tasks/code/{id}/download`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Airflow Task Config
         * @param {string} dagId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirflowTaskConfig: async (dagId: string, taskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dagId' is not null or undefined
            assertParamExists('getAirflowTaskConfig', 'dagId', dagId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getAirflowTaskConfig', 'taskId', taskId)
            const localVarPath = `/easas/scheduled-tasks/airflow/{dagId}/{taskId}/config`
                .replace(`{${"dagId"}}`, encodeURIComponent(String(dagId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Airflow Task Histories
         * @param {string} dagId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirflowTaskHistories: async (dagId: string, taskId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dagId' is not null or undefined
            assertParamExists('getAirflowTaskHistories', 'dagId', dagId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getAirflowTaskHistories', 'taskId', taskId)
            const localVarPath = `/easas/scheduled-tasks/airflow/{dagId}/{taskId}/histories`
                .replace(`{${"dagId"}}`, encodeURIComponent(String(dagId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Python Task Test Job Data
         * @param {string} dagId 
         * @param {string} taskId 
         * @param {string} [referenceTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirflowTaskTestData: async (dagId: string, taskId: string, referenceTime?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dagId' is not null or undefined
            assertParamExists('getAirflowTaskTestData', 'dagId', dagId)
            // verify required parameter 'taskId' is not null or undefined
            assertParamExists('getAirflowTaskTestData', 'taskId', taskId)
            const localVarPath = `/easas/scheduled-tasks/airflow/{dagId}/{taskId}/test-data`
                .replace(`{${"dagId"}}`, encodeURIComponent(String(dagId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (referenceTime !== undefined) {
                for (const [key, value] of Object.entries(referenceTime)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List Code Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCodeFiles: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/easas/scheduled-tasks/code/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Airflow Task Config
         * @param {boolean} [run] 
         * @param {ScheduledTaskDTO} [scheduledTaskDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAirflowTaskConfig: async (run?: boolean, scheduledTaskDTO?: ScheduledTaskDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/easas/scheduled-tasks/airflow/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (run !== undefined) {
                localVarQueryParameter['run'] = run;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(scheduledTaskDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload Task Code
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTaskCode: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadTaskCode', 'file', file)
            const localVarPath = `/easas/scheduled-tasks/code/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EasasScheduledTasksApi - functional programming interface
 * @export
 */
export const EasasScheduledTasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EasasScheduledTasksApiAxiosParamCreator(configuration)
    return {
        /**
         * Download Task Code
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadTaskCode(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadTaskCode(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EasasScheduledTasksApi.downloadTaskCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get Airflow Task Config
         * @param {string} dagId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAirflowTaskConfig(dagId: string, taskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTaskExtendedDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAirflowTaskConfig(dagId, taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EasasScheduledTasksApi.getAirflowTaskConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get Airflow Task Histories
         * @param {string} dagId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAirflowTaskHistories(dagId: string, taskId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScheduledTaskHistoryDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAirflowTaskHistories(dagId, taskId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EasasScheduledTasksApi.getAirflowTaskHistories']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get Python Task Test Job Data
         * @param {string} dagId 
         * @param {string} taskId 
         * @param {string} [referenceTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAirflowTaskTestData(dagId: string, taskId: string, referenceTime?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PythonTaskData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAirflowTaskTestData(dagId, taskId, referenceTime, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EasasScheduledTasksApi.getAirflowTaskTestData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * List Code Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCodeFiles(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CodeFileInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCodeFiles(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EasasScheduledTasksApi.listCodeFiles']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update Airflow Task Config
         * @param {boolean} [run] 
         * @param {ScheduledTaskDTO} [scheduledTaskDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAirflowTaskConfig(run?: boolean, scheduledTaskDTO?: ScheduledTaskDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ScheduledTaskExtendedDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAirflowTaskConfig(run, scheduledTaskDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EasasScheduledTasksApi.updateAirflowTaskConfig']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Upload Task Code
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadTaskCode(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PythonCodeFileDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadTaskCode(file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EasasScheduledTasksApi.uploadTaskCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EasasScheduledTasksApi - factory interface
 * @export
 */
export const EasasScheduledTasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EasasScheduledTasksApiFp(configuration)
    return {
        /**
         * Download Task Code
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadTaskCode(id: string, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.downloadTaskCode(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Airflow Task Config
         * @param {string} dagId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirflowTaskConfig(dagId: string, taskId: string, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledTaskExtendedDTO> {
            return localVarFp.getAirflowTaskConfig(dagId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Airflow Task Histories
         * @param {string} dagId 
         * @param {string} taskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirflowTaskHistories(dagId: string, taskId: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<ScheduledTaskHistoryDTO>> {
            return localVarFp.getAirflowTaskHistories(dagId, taskId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Python Task Test Job Data
         * @param {string} dagId 
         * @param {string} taskId 
         * @param {string} [referenceTime] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAirflowTaskTestData(dagId: string, taskId: string, referenceTime?: string, options?: RawAxiosRequestConfig): AxiosPromise<PythonTaskData> {
            return localVarFp.getAirflowTaskTestData(dagId, taskId, referenceTime, options).then((request) => request(axios, basePath));
        },
        /**
         * List Code Files
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCodeFiles(options?: RawAxiosRequestConfig): AxiosPromise<Array<CodeFileInfo>> {
            return localVarFp.listCodeFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Airflow Task Config
         * @param {boolean} [run] 
         * @param {ScheduledTaskDTO} [scheduledTaskDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAirflowTaskConfig(run?: boolean, scheduledTaskDTO?: ScheduledTaskDTO, options?: RawAxiosRequestConfig): AxiosPromise<ScheduledTaskExtendedDTO> {
            return localVarFp.updateAirflowTaskConfig(run, scheduledTaskDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload Task Code
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadTaskCode(file: File, options?: RawAxiosRequestConfig): AxiosPromise<PythonCodeFileDTO> {
            return localVarFp.uploadTaskCode(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EasasScheduledTasksApi - object-oriented interface
 * @export
 * @class EasasScheduledTasksApi
 * @extends {BaseAPI}
 */
export class EasasScheduledTasksApi extends BaseAPI {
    /**
     * Download Task Code
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EasasScheduledTasksApi
     */
    public downloadTaskCode(id: string, options?: RawAxiosRequestConfig) {
        return EasasScheduledTasksApiFp(this.configuration).downloadTaskCode(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Airflow Task Config
     * @param {string} dagId 
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EasasScheduledTasksApi
     */
    public getAirflowTaskConfig(dagId: string, taskId: string, options?: RawAxiosRequestConfig) {
        return EasasScheduledTasksApiFp(this.configuration).getAirflowTaskConfig(dagId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Airflow Task Histories
     * @param {string} dagId 
     * @param {string} taskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EasasScheduledTasksApi
     */
    public getAirflowTaskHistories(dagId: string, taskId: string, options?: RawAxiosRequestConfig) {
        return EasasScheduledTasksApiFp(this.configuration).getAirflowTaskHistories(dagId, taskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Python Task Test Job Data
     * @param {string} dagId 
     * @param {string} taskId 
     * @param {string} [referenceTime] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EasasScheduledTasksApi
     */
    public getAirflowTaskTestData(dagId: string, taskId: string, referenceTime?: string, options?: RawAxiosRequestConfig) {
        return EasasScheduledTasksApiFp(this.configuration).getAirflowTaskTestData(dagId, taskId, referenceTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List Code Files
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EasasScheduledTasksApi
     */
    public listCodeFiles(options?: RawAxiosRequestConfig) {
        return EasasScheduledTasksApiFp(this.configuration).listCodeFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Airflow Task Config
     * @param {boolean} [run] 
     * @param {ScheduledTaskDTO} [scheduledTaskDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EasasScheduledTasksApi
     */
    public updateAirflowTaskConfig(run?: boolean, scheduledTaskDTO?: ScheduledTaskDTO, options?: RawAxiosRequestConfig) {
        return EasasScheduledTasksApiFp(this.configuration).updateAirflowTaskConfig(run, scheduledTaskDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload Task Code
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EasasScheduledTasksApi
     */
    public uploadTaskCode(file: File, options?: RawAxiosRequestConfig) {
        return EasasScheduledTasksApiFp(this.configuration).uploadTaskCode(file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * JobsApi - axios parameter creator
 * @export
 */
export const JobsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create Job
         * @param {JobInfoDTO} [jobInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJob: async (jobInfoDTO?: JobInfoDTO, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(jobInfoDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete Job
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJob: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteJob', 'id', id)
            const localVarPath = `/jobs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/jobs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update Schedule
         * @param {string} id 
         * @param {Schedule} [schedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsUpdateScheduleIdPut: async (id: string, schedule?: Schedule, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('jobsUpdateScheduleIdPut', 'id', id)
            const localVarPath = `/jobs/updateSchedule/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(schedule, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * JobsApi - functional programming interface
 * @export
 */
export const JobsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = JobsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create Job
         * @param {JobInfoDTO} [jobInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createJob(jobInfoDTO?: JobInfoDTO, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createJob(jobInfoDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobsApi.createJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Delete Job
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteJob(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteJob(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobsApi.deleteJob']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get Jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getJobs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<JobInfoDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getJobs(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobsApi.getJobs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update Schedule
         * @param {string} id 
         * @param {Schedule} [schedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async jobsUpdateScheduleIdPut(id: string, schedule?: Schedule, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.jobsUpdateScheduleIdPut(id, schedule, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['JobsApi.jobsUpdateScheduleIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * JobsApi - factory interface
 * @export
 */
export const JobsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = JobsApiFp(configuration)
    return {
        /**
         * Create Job
         * @param {JobInfoDTO} [jobInfoDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJob(jobInfoDTO?: JobInfoDTO, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createJob(jobInfoDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete Job
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJob(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteJob(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Jobs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJobs(options?: RawAxiosRequestConfig): AxiosPromise<Array<JobInfoDTO>> {
            return localVarFp.getJobs(options).then((request) => request(axios, basePath));
        },
        /**
         * Update Schedule
         * @param {string} id 
         * @param {Schedule} [schedule] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        jobsUpdateScheduleIdPut(id: string, schedule?: Schedule, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.jobsUpdateScheduleIdPut(id, schedule, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * JobsApi - object-oriented interface
 * @export
 * @class JobsApi
 * @extends {BaseAPI}
 */
export class JobsApi extends BaseAPI {
    /**
     * Create Job
     * @param {JobInfoDTO} [jobInfoDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public createJob(jobInfoDTO?: JobInfoDTO, options?: RawAxiosRequestConfig) {
        return JobsApiFp(this.configuration).createJob(jobInfoDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete Job
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public deleteJob(id: string, options?: RawAxiosRequestConfig) {
        return JobsApiFp(this.configuration).deleteJob(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Jobs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public getJobs(options?: RawAxiosRequestConfig) {
        return JobsApiFp(this.configuration).getJobs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update Schedule
     * @param {string} id 
     * @param {Schedule} [schedule] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JobsApi
     */
    public jobsUpdateScheduleIdPut(id: string, schedule?: Schedule, options?: RawAxiosRequestConfig) {
        return JobsApiFp(this.configuration).jobsUpdateScheduleIdPut(id, schedule, options).then((request) => request(this.axios, this.basePath));
    }
}



