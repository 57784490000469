import React, {RefObject} from 'react';
import AppHeader from "./components/general/AppHeader/AppHeader";
import DataCollector from "./components/data-collector/DataCollector/DataCollector";
import {Toast} from "primereact/toast";
import NotificationPopUp from "./util/NotificationPopUp";
import {Route, Routes} from "react-router-dom";
import DataWriter from "./components/data-writer/DataWriter/DataWriter";
import UrlInputForm from "./components/UrlInputForm";
import {ApiConfig} from "./axios/Config";
import UserService, {Permission} from "./service/UserService";
import Forbidden from "./components/Forbidden";
import {apisStore} from "./context/GlobalStates";
import JobRunner from 'components/job-runner/JobRunner/JobRunner';
import './App.css';

class App extends React.Component {
    private readonly dataCollector: RefObject<DataCollector> = React.createRef<DataCollector>();
    private readonly dataWriter: RefObject<DataWriter> = React.createRef<DataWriter>();
    private readonly jobRunner: RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    render() {
        if (!UserService.isPermited(Permission.DATAHUB_USE) && apisStore.getState().serviceId) {
            return <Forbidden/>
        }

        return (
            ApiConfig.baseUrl || apisStore.getState().serviceId ?
                <div className={"App"}>
                    <Toast ref={NotificationPopUp.toast}/>
                    <div className={"appHeader"}>
                        <AppHeader dataCollectorRef={this.dataCollector}
                                   dataWriterRef={this.dataWriter}
                                   jobRunnerRef={this.jobRunner}
                        />
                    </div>
                    <div className={"appBody"}>
                        <Routes>
                            <Route path="/" element={<DataCollector ref={this.dataCollector}/>}/>
                            <Route path="/data_writer" element={<DataWriter ref={this.dataWriter}/>}/>
                            <Route path="/job_runner" element={<JobRunner ref={this.jobRunner} />}/>
                            <Route path="/*" element={<h1 style={{color: "white", textAlign: "center"}}>
                                Page not found
                            </h1>}/>
                        </Routes>
                    </div>
                </div>
                :
                <UrlInputForm/>
        )
    }
}

export default App;
