import React, { useState } from "react";
import { Tooltip } from "react-tooltip";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import RemoveConfirmation from "./RemoveConfirmation";
import NotificationPopUp from "util/NotificationPopUp";
import { SchemaType } from "serviceapi/dataPusherApi";

type DeleteInputOutputProps = {
  type: SchemaType;
  inputOrOutputId: string;
  confirm(): void;
  disabled?: boolean;
};

const DeleteInputOutput = ({
  type,
  inputOrOutputId,
  confirm,
  disabled,
}: DeleteInputOutputProps) => {
  const [dialog, setDialog] = useState<boolean>(false);

  return (
    <>
      <Tooltip
        id={
          type === SchemaType.Input ? "remove-old-input" : "remove-old-output"
        }
      />
      <Button
        className="add-pipeline-button p-button-danger"
        icon="pi pi-trash"
        onClick={() => setDialog(true)}
        data-tooltip-content={
          type === SchemaType.Input ? "Remove input" : "Remove output"
        }
        data-tooltip-id={
          type === SchemaType.Input ? "remove-old-input" : "remove-old-output"
        }
        disabled={disabled}
      />
      {dialog && (
        <Dialog
          onHide={() => setDialog(false)}
          visible={dialog}
          header="Remove output"
        >
          <RemoveConfirmation
            type={type}
            inputOrOutputId={inputOrOutputId}
            confirm={() => {
              confirm();
              setDialog(false);
              NotificationPopUp.show(
                type === SchemaType.Input
                  ? "Input removed successfully"
                  : "Output removed successfully",
                "success"
              );
            }}
          />
        </Dialog>
      )}
    </>
  );
};

export default DeleteInputOutput;
