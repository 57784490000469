import React from "react";
import { ConfirmPopup } from "primereact/confirmpopup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { SchemaType } from "serviceapi/dataPusherApi";

type RemoveConfirmationProps = {
  type: SchemaType;
  inputOrOutputId: string;
  confirm(): void;
};

const RemoveConfirmation = ({
  type,
  inputOrOutputId,
  confirm,
}: RemoveConfirmationProps) => {
  const [value, setValue] = React.useState<string>("");

  return (
    <div>
      <ConfirmPopup />

      <div className={"text-alignment"}>
        To remove this reader write pipeline $
        {type === SchemaType.Input ? "input" : "output"} id: [
        <span className={"writer-name"}>
          <b>{inputOrOutputId}</b>
        </span>
        ]
      </div>

      <InputText
        style={{ alignSelf: "center" }}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />

      <Button
        id="confirm-button"
        style={{ transform: "scale(90%)" }}
        label="Remove"
        icon="pi pi-times"
        onClick={confirm}
        disabled={value !== inputOrOutputId}
      />
    </div>
  );
};

export default RemoveConfirmation;
