import { Dialog } from "primereact/dialog";
import React from "react";
import { DataType } from "../DataWriter/DataWriter";

interface InputsOutputsHistoryProps {
  dataType: DataType;
  dataHistory: any[];
  isDataHistoryDialogVisible: boolean;
  setIsDataHistoryDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const InputsOutputsHistory = (props: InputsOutputsHistoryProps) => {
  return (
    <Dialog
      header={`${
        props.dataType === DataType.INPUT ? "Inputs" : "Outputs"
      } history`}
      visible={props.isDataHistoryDialogVisible}
      style={{
        width: "30%",
        height: "46%",
        position: "absolute",
        bottom: props.dataType === DataType.INPUT ? undefined : "0px",
        top: props.dataType === DataType.OUTPUT ? undefined : "78px",
        right: "0px",
      }}
      modal={false}
      resizable
      onHide={() => props.setIsDataHistoryDialogVisible(false)}
    >
      <div>
        <pre>{JSON.stringify(props.dataHistory, null, 2)}</pre>
      </div>
    </Dialog>
  );
};

export default InputsOutputsHistory;
