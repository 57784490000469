import DeleteInputOutput from "./DeleteOutput/DeleteInputOutput";
import InputsOrOutputsDropdown from "./OutputsDropdown/InputsOrOutputsDropdown";
import AddPipeInputOrOutput from "./AddOutput/AddPipeInputOrOutput";
import { SchemaType } from "serviceapi/dataPusherApi";

type MainComponentsWrapperProps = {
  editorConfig: any;
  editorFullConfig: any;
  setEditorFullConfig: (conf: any) => void;
  selectInput?: (confId?: string) => void;
  selectOutput?: (confId?: string) => void;
  configChanged: () => void;
};

const MainComponentsWrapper = ({
  editorConfig,
  editorFullConfig,
  setEditorFullConfig,
  selectInput,
  selectOutput,
  configChanged,
}: MainComponentsWrapperProps) => {
  const normalizedInputConfig = Array.isArray(editorFullConfig?.inputConfig)
    ? editorFullConfig.inputConfig
    : editorFullConfig?.inputConfig
    ? [editorFullConfig.inputConfig]
    : [];

  const normalizedOutputConfig = Array.isArray(editorFullConfig?.outputConfig)
    ? editorFullConfig.outputConfig
    : [];

  const inputOptions = normalizedInputConfig.map((ob: any) => ({
    label: ob.id,
    value: ob.id,
  }));

  const outputOptions = normalizedOutputConfig.map((ob: any) => ({
    label: ob.id,
    value: ob.id,
  }));

  return (
    <div>
      {selectOutput && (
        <div className="flex gap-1 justify-content-center align-items-center pr-1">
          <AddPipeInputOrOutput
            type={SchemaType.Output}
            allInputsOrOutputs={normalizedOutputConfig}
            addNewOutput={(conf) => {
              setEditorFullConfig({
                ...editorFullConfig,
                outputConfig: [...normalizedOutputConfig, conf],
              });
              configChanged();
            }}
          />
          <InputsOrOutputsDropdown
            type={SchemaType.Output}
            options={outputOptions}
            onSelect={selectOutput}
            selected={editorConfig?.id || ""}
            setSelected={selectOutput}
          />
          <DeleteInputOutput
            type={SchemaType.Output}
            inputOrOutputId={editorConfig?.id || ""}
            confirm={() => {
              const newOutputConfig = normalizedOutputConfig.filter(
                (ob: any) => ob.id !== editorConfig.id
              );
              setEditorFullConfig({
                ...editorFullConfig,
                outputConfig: newOutputConfig,
              });
              configChanged();
            }}
            disabled={outputOptions.length <= 1}
          />
        </div>
      )}
      {selectInput && (
        <div className="flex gap-1 justify-content-center align-items-center pr-1">
          <AddPipeInputOrOutput
            type={SchemaType.Input}
            allInputsOrOutputs={normalizedInputConfig}
            addNewInput={(conf) => {
              setEditorFullConfig({
                ...editorFullConfig,
                inputConfig: [...normalizedInputConfig, conf],
              });
              configChanged();
            }}
          />
          <InputsOrOutputsDropdown
            type={SchemaType.Input}
            options={inputOptions}
            onSelect={selectInput}
            selected={editorConfig?.id || ""}
            setSelected={selectInput}
          />
          <DeleteInputOutput
            type={SchemaType.Input}
            inputOrOutputId={editorConfig?.id || ""}
            confirm={() => {
              const newInputConfig = normalizedInputConfig.filter(
                (ob: any) => ob.id !== editorConfig.id
              );
              setEditorFullConfig({
                ...editorFullConfig,
                inputConfig: newInputConfig,
              });
              configChanged();
            }}
            disabled={inputOptions.length <= 1}
          />
        </div>
      )}
    </div>
  );
};

export default MainComponentsWrapper;
