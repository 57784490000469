import { ListBox } from "primereact/listbox";
import Encoder from "../../data-writer/Encoder/Encoder";
import { ProgressSpinner } from "primereact/progressspinner";
import { forwardRef, useState, useEffect, useRef, useCallback } from "react";
import { WriterView } from "writer/WriterView";
import Apis from "serviceapi/ApiImports";
import { Button } from "primereact/button";
import { apisStore } from "context/GlobalStates";
import {
  JobInfoDTO,
  JobsApi,
  ScheduledTaskExtendedDTO,
  EasasScheduledTasksApi,
} from "serviceapi";
import { Messages } from "primereact/messages";
import "./JobRunner.scss";
import JobRunnerToggleButton from "../job-runner-toggle-button/JobRunnerToggleButton";

const JobRunner = forwardRef<HTMLDivElement>((props, ref) => {
  const [listLoad, setListLoad] = useState<boolean>(true);
  const [jobs, setJobs] = useState<JobInfoDTO[]>([]);
  const [selectedJob, setSelectedJob] = useState<JobInfoDTO | null>(null);
  const [currentTask, setCurrentTask] =
    useState<ScheduledTaskExtendedDTO | null>(null);
  const [viewTabIndex, setViewTabIndex] = useState<number>(0);
  const [editorFullConfig, setEditorFullConfig] = useState<any>({});
  const [readScript, setReadScript] = useState<boolean>(false);

  const encoderRef = useRef<any>(null);
  const writerViewRef = useRef<any>(null);
  const msgs = useRef<Messages>(null);

  const apis = Apis("jobrunner");

  const apiScheduled = apisStore.getState()?.jobRunner
    ?.easasScheduledTasksApi as EasasScheduledTasksApi;

  const apiJobs = apisStore.getState()?.jobRunner?.jobsApi as JobsApi;

  const fetchJobs = useCallback(async () => {
    try {
      setListLoad(true);
      const response = await apiJobs.getJobs();
      const fetchedJobs = response.data;
      setJobs(fetchedJobs);

      if (fetchedJobs.length > 0) {
        const firstJob = fetchedJobs[0];
        setSelectedJob(firstJob);
        fetchTaskDetails(firstJob.taskId);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
      msgs.current?.show({
        severity: "error",
        content: "Failed to fetch jobs",
        closable: false,
      });
    } finally {
      setListLoad(false);
    }
  }, [apiJobs]);

  const fetchTaskDetails = useCallback(
    async (taskId: string) => {
      try {
        const response = await apiScheduled.getAirflowTaskConfig("1", taskId);
        setCurrentTask(response.data);
      } catch (error) {
        console.error("Error fetching task details:", error);
        msgs.current?.show({
          severity: "error",
          content: "Failed to fetch task details",
          closable: false,
        });
      }
    },
    [apiScheduled]
  );

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleJobSelection = async (job: JobInfoDTO) => {
    setSelectedJob(job);
    await fetchTaskDetails(job.taskId);
  };

  const listBoxItemTemplate = (option: any) => (
    <span className="flex flex-row justify-content-between align-items-center">
      <span className="long-name-tooltip" style={{ wordBreak: "break-word" }}>
        {option.taskTitle}
      </span>

      <JobRunnerToggleButton
        jobRunner={option}
        onJobRunnerToggled={() => console.log("Toggled")}
      />
    </span>
  );

  return (
    <div ref={ref}>
      <div className="data-writer">
        <div className="left-side">
          <div className="list-box">
            {listLoad ? (
              <div className="list-box-loading">
                <ProgressSpinner />
              </div>
            ) : (
              <ListBox
                value={selectedJob}
                options={jobs}
                onChange={(e) => handleJobSelection(e.value)}
                filter
                filterPlaceholder="Search"
                filterBy="id,name"
                optionLabel="name"
                itemTemplate={listBoxItemTemplate}
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
                listStyle={{ height: "calc(100% - 36px)" }}
              />
            )}
          </div>
        </div>
        <div className="tool-box-container">
          <div className="tool-box-top-container">
            {/* <CreateNewTaskButton              
              refreshTasks={fetchJobs}
              createTask={createTask}
            /> */}
            <Button
              disabled
              className="function-buttons-container-container-button p-button-secondary p-button-sm"
              icon="pi pi-pencil"
              tooltip="Open processor config editor"
              onClick={() => console.log("Open processor config editor")}
            />
            <Button
              disabled
              icon="pi pi-save"
              className="p-button-primary"
              onClick={() => console.log("Save")}
            />
          </div>
        </div>
        <div className="right-side">
          <div className="tab-view-window">
            <div className="function-buttons-container">
              <div className="button-container-scheme-picker">
                <div
                  className={`tab-view-button ${
                    viewTabIndex === 0 ? "active" : ""
                  } first-button`}
                  onClick={() => setViewTabIndex(0)}
                >
                  Config
                </div>
                <div
                  className={`tab-view-button ${
                    viewTabIndex === 1 ? "active" : ""
                  } middle-button`}
                  // onClick={() => setViewTabIndex(1)}
                  onClick={() => console.log("Open processor config editor")}
                >
                  Base64 code
                </div>
              </div>
            </div>
            <div className="json-window">
              {viewTabIndex === 1 && selectedJob ? (
                <Encoder
                  config={editorFullConfig}
                  updateConfig={setEditorFullConfig}
                  id={selectedJob.taskId}
                  ref={encoderRef}
                  managementApi={apis.managementApiConst}
                  readScript={readScript}
                  setReadScript={setReadScript}
                  closeWindow={() => setReadScript(false)}
                  updateWriters={() => console.log("Update writers")}
                />
              ) : (
                <WriterView
                  writer={currentTask || ""}
                  schema={"yourSchema"}
                  ref={writerViewRef}
                  errorIsInEditor={(res: boolean) => console.log(res)}
                  changed={(res: boolean) => console.log(res)}
                  saveCallback={() => console.log("Save button clicked")}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default JobRunner;
