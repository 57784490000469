import {create} from "zustand";
import Apis, {apisType} from "../serviceapi/ApiImports";

type ApiStoreType = {
    dataCollector: apisType,
    dataPusher: apisType,
    jobRunner: apisType,
    serviceId: string,
    initialized: boolean
}

export const apisStore = create<ApiStoreType>(
    () => (
        {
            dataCollector: {} as apisType,
            dataPusher: {} as apisType,
            jobRunner: {} as apisType,
            serviceId: "",
            initialized: false
        }
    )
)