import React from "react";
import { Dropdown } from "primereact/dropdown";

import "./OutputsDropdown.scss";
import { SchemaType } from "serviceapi/dataPusherApi";

type InputsOrOutputsDropdownProps = {
  type: SchemaType;
  onSelect: (confId: string) => void;
  selected: string;
  setSelected: (confId: string) => void;
  options: Array<{ label: string; value: string }>;
};

const InputsOrOutputsDropdown: React.FC<InputsOrOutputsDropdownProps> = ({
  type,
  options = [],
  onSelect,
  setSelected,
  selected,
}) => {
  const handleChange = (value: string) => {
    onSelect(value);
    setSelected(value);
  };

  return (
    <Dropdown
      value={selected}
      options={options}
      onChange={(e) => handleChange(e.value)}
      className="p-dropdown-sm"
      disabled={options.length === 0}
      placeholder={`Select a ${type === SchemaType.Input ? "Input" : "Output"}`}
    />
  );
};

export default InputsOrOutputsDropdown;
