import React, {Component} from 'react';
import {FileUpload, FileUploadHandlerParam} from "primereact/fileupload";
import NotificationPopUp from "../../../util/NotificationPopUp";
import ImportFileReaderStatusWindow from "../ImportFileReaderStatusWindow/ImportFileReaderStatusWindow";
import './DataImport.scss';
import {Tooltip} from "primereact/tooltip";
import {apisType} from "../../../serviceapi/ApiImports";
import {ProgressSpinner} from "primereact/progressspinner";
import {apisStore} from "../../../context/GlobalStates";

type DataImportProps = {
}

type DataImportState = {
    dataCollectorArray: Array<any>,
    dataCollectorExists: Array<boolean>,
    dataWriterArray: Array<any>,
    dataWriterExists: Array<boolean>,
    totalSize: number,
    loading: boolean
}

class DataImport extends Component<DataImportProps, DataImportState> {
    private collectorLoaded = false;
    private writerLoaded = false;
    private dataCollectorApi: apisType;
    private dataPusherApi: apisType;

    constructor(props: Readonly<DataImportProps>) {
        super(props);

        this.state = {
            dataCollectorArray: [],
            dataCollectorExists: [],
            dataWriterArray: [],
            dataWriterExists: [],
            totalSize: 0,
            loading: false
        }
        this.dataCollectorApi = apisStore.getState().dataCollector
        this.dataPusherApi = apisStore.getState().dataPusher
    }

    uploadHandler = (e: FileUploadHandlerParam) => {
        this.setState({loading: true})
        if (e.files.length === 0) {
            NotificationPopUp.show("No file selected.", 'error')
            return
        } else if (e.files[0].type !== "application/json") {
            NotificationPopUp.show("Unexpected type of file. Only JSON file is allowed.", 'error')
            return
        } else {
            this.convertFilesToJSON(e);
        }
    }

    private convertFilesToJSON = (e: FileUploadHandlerParam) => {
        e.files[0].text()
            .then((res) => {
                if (res.length === 0) {
                    NotificationPopUp.show("File is empty", 'error')
                    return
                }
                let tempObj = JSON.parse(res);
                if (tempObj.dataCollector) {
                    this.uploadCollectorArray(tempObj.dataCollector)
                }
                if (tempObj.dataWriter) {
                    this.uploadWriterArray(tempObj.dataWriter)
                }

            })
            .catch(er => console.error(er))
    }

    private uploadCollectorArray = (arr: Array<any>) => {
        if (!arr.length) {
            NotificationPopUp.show("File is empty", "error")
            return;
        }

        let tempArray: Array<any> = []

        Promise.all(
            arr.map(obj => {
                return this.dataCollectorApi.managementApi?.parseConfig(obj)
                    .then(() => obj)
            })
        )
            .then(res => {
                this.collectorLoaded = true;
                tempArray = res;
                Promise.all(
                    res.map(obj => this.dataCollectorApi.managementApi?.doesReaderExist(obj.id))
                )
                    .then(res => {
                        this.setState({
                            dataCollectorExists: res.map(exist => exist?.data!!),
                            dataCollectorArray: tempArray
                        })
                    })
                    .catch(err => NotificationPopUp.show(err.message, 'error'))
            })
            .catch(err => NotificationPopUp.show(err.message, 'error'))
    }

    private uploadWriterArray = (arr: Array<any>) => {
        if (!arr.length) {
            NotificationPopUp.show("File is empty", "error")
            return;
        }

        let tempArray: Array<any> = []

        Promise.all(
            arr.map(obj => {
                return this.dataPusherApi.managementApiConst?.parseConfig(obj)
                    .then(() => obj)
            })
        )
            .then(res => {
                this.writerLoaded = true;
                tempArray = res;
                Promise.all(
                    res.map(obj => this.dataPusherApi.managementApiConst?.doesPipelineExist(obj.id))
                )
                    .then(res => {
                        this.setState({
                            dataWriterExists: res.map(exist => exist?.data!!),
                            dataWriterArray: tempArray
                        })
                    })
                    .catch(err => NotificationPopUp.show(err.message, 'error'))
            })
            .catch(err => NotificationPopUp.show(err.message, 'error'))
    }

    private removeState = () => {
        this.collectorLoaded = false;
        this.writerLoaded = false;
        this.setState({
            dataCollectorExists: [],
            dataCollectorArray: [],
            dataWriterArray: [],
            dataWriterExists: [],
            loading: false
        })
    }

    private itemTemplate = (e: any) => {
        return <div className={"item-template"}>
            <div>{this.getName(e.name)}</div>
            <div>{this.byteExchange(e.size)}</div>
        </div>
    }

    private getName = (name: string) => {
        if (name.length > 35) {
            let newName = name.slice(0, 30) + "...";
            return <>
                <Tooltip target=".tooltip-import"/>
                <span className="tooltip-import" data-pr-tooltip={name}>
                    {newName}
                </span>
            </>
        }

        return <>{name}</>
    }

    private byteExchange = (bytes: number) => {
        if (bytes < 1000) {
            return bytes + "B"
        } else if (bytes >= 1000 && bytes < 1000000) {
            return (bytes / 1000).toFixed(3) + "KB";
        } else if (bytes >= 1000000 && bytes < 1000000000) {
            return (bytes / 1000000).toFixed(3) + "MB";
        } else if (bytes >= 1000000000 && bytes < 1000000000000) {
            return (bytes / 1000000000).toFixed(3) + "GB";
        } else if (bytes >= 1000000000000) {
            return (bytes / 1000000000000).toFixed(3) + "TB";
        }


    }

    render() {
        return (
            <div className={"import-window"}>
                <div className={"upload-window"}>
                    <FileUpload name="jsonFiles"
                                customUpload={true}
                                uploadHandler={this.uploadHandler}
                                accept=".json"
                                maxFileSize={1000000000000}
                                emptyTemplate={<p className="l-0">Drag and drop file to here to upload.</p>}
                                itemTemplate={this.itemTemplate}
                                onRemove={this.removeState}
                                onClear={this.removeState}
                                contentStyle={{padding: "0 1rem", minHeight: "30px", backgroundColor: "black"}}
                                headerStyle={{backgroundColor: "black"}}
                    />
                </div>
                {this.state.loading &&
                    <>
                        {(!this.writerLoaded && !this.collectorLoaded) ?
                            <ProgressSpinner style={{overflow: "hidden"}}/>
                            :
                            <div className={"status-window"}>
                                <ImportFileReaderStatusWindow collectorExists={this.state.dataCollectorExists}
                                                              collectorArray={this.state.dataCollectorArray}
                                                              writerExists={this.state.dataWriterExists}
                                                              writerArray={this.state.dataWriterArray}
                                />
                            </div>
                        }
                    </>

                }
            </div>
        );
    }
}

export default DataImport;